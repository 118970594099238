@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.font-landing * {
    font-family: 'Open Sans', sans-serif !important;
}

* {
    font-family: 'Open Sans', sans-serif !important;
}
.font-color {
    color: #282728;
}

.number-bold{
    font-weight: bold;
    color: #000;
}

hr {
    border: 1px solid #272f51;
    width: 11%;
    margin-left: 0;
}
.font-footer{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: lighter;
    font-size: 25px;
    color: white;
}

.button_ovalado{
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    background:#2E3B55 !important;
}

.button_ovalado_disabled{
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
}

.MuiTreeItem-label{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 18px !important;
}

.MuiCollapse-wrapperInner > p {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
}

.react-responsive-modal-modal{
    border-radius: 25px;
    background-color: #F3F3F3;
}

#modal-confirmacion > *{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: lighter;
    background-color: #F3F3F3;
    border: 5;
    
}

.content-community-face {
    height: 100%;
    background-image: url("../img/fondo_box_01.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 100px 0px;
    margin-bottom: 25px;
}

.content-community-face h1{
    margin: 0px;
}

.content-community-face button {
    background: none;
    border: solid 1px #fff;
    border-radius: 100px;
    color: #fff;
    padding: 25px 70px;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;
}

.content-carrusel {
    width: 80%;
    margin-left: 10%;
}

.content-carrusel_item {
    display: flex !important;
    justify-content: center;
    text-align: center;
}

.content-carrusel_item img {
    display: initial !important;
}

.content-collaborators {
    padding: 35px 40px;
}
  
.content-header-collaborators {
    text-align: center;
    margin: 52px 0px;
}

.content-collaborators-button {
    margin: 30px 0px;
   text-align: center;
}

.content-collaborators-button a {
    background-color: #2E3B55;
    border: solid 1px #2E3B55;
    border-radius: 100px;
    color: #fff !important;
    padding: 25px 70px;
    margin-top: 50px;
    font-weight: bold;
    cursor: pointer;
    top: 33px;
    position: relative;
    text-decoration: none;
}

.content-header-collaborators span:nth-child(1) {
    font-size: 25px;
}

.conten-contact div {
    display: flex;
    margin-top: 10px;
}

.conten-contact div > img {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .grid-paper {
        display: none !important;
    }

    .grid-paper-mobil {
        display: flex !important;
    }

    .btn-login {
        display: none !important;
    }

    .btn-login-movil {
        display: block !important;
    }

    .aviso-margin {
        margin-top: 15px;
        margin-bottom: 133px;
    }

    .acordion-mobil {
        display: block;
        text-align: center;
    }

}
  
@media only screen and (min-width: 600px) {
   .grid-paper {
        display: flex !important;
   }

   .grid-paper-mobil {
        display: none !important;
    }

    .btn-login {
        display: block !important;
    }

    .btn-login-movil {
        display: none !important;
    }
    
    .acordion-mobil {
        display: none;
    }
}