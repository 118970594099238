@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.soporte-ti{
    background: #4285F4;
    color: #fff;
}

.analisis{
    background: #E84335;
    color: #fff;
}

.gestion{
    background: #E6ac27;
    color: #fff;
}

.ux{
    background: #1F8839;
    color: #fff;
}

.MuiCardHeader-content  > span{
    font-weight: lighter;
    font-size: 1.2vw;
    width: 10vw;
    height: 14vh;
}

.card-cert {
    border:1px #aaaaaa;
    margin-bottom: 25px;
}

.card-cert > .MuiPaper-root{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.MuiCardContent-root > p{
    font-size: 16px;
    margin-top: 2%;
}

.boton-cursera {
    margin-top: 60px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.boton-cursera-boton {
    width: 9%;
    margin-left: 10px;
}

.container-redes {
    display: flex;
    margin-bottom: 30px;
    margin-top: 20px;
}

.container-redes_img {
    display: flex;
    width: 100%;
}

.container-redes_mensaje {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 20px;
}

.cert-finish {
    padding: 2px 26px;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .cards-movil {
        display: flex;
    }

    .cards-no-movil {
        display: none !important;
    }

    .display-card-mode {
        display: block;
    }
}
  
@media only screen and (min-width: 600px) {
    .cards-movil {
       display: none; 
    }

    .cards-no-movil {
        display: flex !important;
    }

    .display-card-mode {
        display: flex;
    }
}
