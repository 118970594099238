@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap);
body {
  background-color: #FFFFFF;
  padding: 0px;
  margin: 0;
}
h1, h2, p, ul, li {
  font-family: sans-serif;
}
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.header {
  background-color: #2E3B55;
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: #FFF;
  padding: 20px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

.content-formulario {
  padding: 20px 130px;
}

.active {
  background-color: #0099FF;
}

.footer{
  padding: 1rem;
  background-color: #2E3B55;
  color: 'white';
  bottom: 0;
  left: 0;
  /*mwidth: 100%;*/
  max-width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  height: 150px;
  justify-content: center;
}

.App {
  font-family: sans-serif;
  text-align: center;
  display: flex;
}

.div_test{
  border: 1px solid red;
}

.iconDetails {
  margin-left:2%;
 float:left; 
 height:40px;
 width:40px; 
 } 
 
 .container2 {
     width:100%;
     height:auto;
     padding:1%;
 }

 test {
  margin:0px;
}

.column1 {
  text-align: left;
  float: left;
  width: 10%;
  max-width: 75px;
  max-height: 75px;
  
}

.column2 {
  float: left;
  width: 80%;
  text-align: left;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  margin-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
  text-align: left;
}

.logo {
  max-width: 150px;
  max-height: 150px;
  margin-right: 10px;
}

.logoL {
  max-width: 150px;
  max-height: 150px;
  margin-right: 10px;
}

.row10:after {
  content: "";
  display: table;
  clear: both;
  text-align: left;
}

.column10 {
  color: 'white';
  text-align: left;
  float: left;
  width: 50%;
  max-width: 75px;
  max-height: 75px;
}

.loginButton {
  border-color: '#2196F3';
  color: '#2196F3';
}

.button-link {
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  color: black;
  content: 'Select some files';
  display: inline-block;
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.custom-file-input:hover::before {
  border-color: black;
}
.custom-file-input:active::before {
  background: -webkit-linear-gradient(to bottom, #e3e3e3, #f9f9f9);
}

.form-cheks .css-j204z7-MuiFormControlLabel-root {
  align-items: flex-start;
}

.form-cheks .css-ahj2mt-MuiTypography-root {
  margin-top: 8px;
}

@media only screen and (max-width: 600px) {
  .footer-no-mobil{
    display: none;
  }

  .footer-mobil{
    display: block;
    -webkit-clip-path: polygon(0 2%, 100% 7%, 100% 100%, 0% 100%);
    clip-path: polygon(0 2%, 100% 7%, 100% 100%, 0% 100%);
  }

  .content-formulario {
    padding: 20px 20px;
  }

  .content-no-movil {
    padding: 20px;
  }
}

@media only screen and (min-width: 600px) {
  .footer-no-mobil{
    display: flex;
  }

  .footer-mobil{
    display: none;
  }

  .content-no-movil {
    padding: 0px;
  }
}
.font-landing * {
    font-family: 'Open Sans', sans-serif !important;
}

* {
    font-family: 'Open Sans', sans-serif !important;
}
.font-color {
    color: #282728;
}

.number-bold{
    font-weight: bold;
    color: #000;
}

hr {
    border: 1px solid #272f51;
    width: 11%;
    margin-left: 0;
}
.font-footer{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: lighter;
    font-size: 25px;
    color: white;
}

.button_ovalado{
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
    background:#2E3B55 !important;
}

.button_ovalado_disabled{
    border: 1px solid #ffffff !important;
    border-radius: 40px !important;
}

.MuiTreeItem-label{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 18px !important;
}

.MuiCollapse-wrapperInner > p {
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px !important;
}

.react-responsive-modal-modal{
    border-radius: 25px;
    background-color: #F3F3F3;
}

#modal-confirmacion > *{
    font-family: 'Open Sans', sans-serif !important;
    font-weight: lighter;
    background-color: #F3F3F3;
    border: 5;
    
}

.content-community-face {
    height: 100%;
    background-image: url(/static/media/fondo_box_01.f0f48d1d.jpg);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    color: #fff;
    padding: 100px 0px;
    margin-bottom: 25px;
}

.content-community-face h1{
    margin: 0px;
}

.content-community-face button {
    background: none;
    border: solid 1px #fff;
    border-radius: 100px;
    color: #fff;
    padding: 25px 70px;
    margin-top: 30px;
    font-weight: bold;
    cursor: pointer;
}

.content-carrusel {
    width: 80%;
    margin-left: 10%;
}

.content-carrusel_item {
    display: flex !important;
    justify-content: center;
    text-align: center;
}

.content-carrusel_item img {
    display: initial !important;
}

.content-collaborators {
    padding: 35px 40px;
}
  
.content-header-collaborators {
    text-align: center;
    margin: 52px 0px;
}

.content-collaborators-button {
    margin: 30px 0px;
   text-align: center;
}

.content-collaborators-button a {
    background-color: #2E3B55;
    border: solid 1px #2E3B55;
    border-radius: 100px;
    color: #fff !important;
    padding: 25px 70px;
    margin-top: 50px;
    font-weight: bold;
    cursor: pointer;
    top: 33px;
    position: relative;
    text-decoration: none;
}

.content-header-collaborators span:nth-child(1) {
    font-size: 25px;
}

.conten-contact div {
    display: flex;
    margin-top: 10px;
}

.conten-contact div > img {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .grid-paper {
        display: none !important;
    }

    .grid-paper-mobil {
        display: flex !important;
    }

    .btn-login {
        display: none !important;
    }

    .btn-login-movil {
        display: block !important;
    }

    .aviso-margin {
        margin-top: 15px;
        margin-bottom: 133px;
    }

    .acordion-mobil {
        display: block;
        text-align: center;
    }

}
  
@media only screen and (min-width: 600px) {
   .grid-paper {
        display: flex !important;
   }

   .grid-paper-mobil {
        display: none !important;
    }

    .btn-login {
        display: block !important;
    }

    .btn-login-movil {
        display: none !important;
    }
    
    .acordion-mobil {
        display: none;
    }
}
.link {
    color: #4285f4;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

.link-button {
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background: none;
  margin-top: 10px;
}
  
.spanHeader{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.title {
    flex-grow: 1;
    text-align: right;
    color:gray;
}

.captcha {
  text-align: center;
  margin: 38px 60px;
  color:red;
}

@media only screen and (max-width: 600px) {
  #appbar {
    display: none;
  }

  #appbar-mobil {
    display: block;
  }
  
  .loginButton{
    width: 20%;
  }

  .captcha {
    text-align: center;
    margin: 38px -15px;
    color:red;
  }

  .captcha > div > div > div {
    width: 277px;
  }
}

@media only screen and (min-width: 600px) {
  #appbar {
    display: block;
  }

  #appbar-mobil {
    display: none;
  }

  .loginButton{
    width: 10%;
  }
}
.soporte-ti{
    background: #4285F4;
    color: #fff;
}

.analisis{
    background: #E84335;
    color: #fff;
}

.gestion{
    background: #E6ac27;
    color: #fff;
}

.ux{
    background: #1F8839;
    color: #fff;
}

.MuiCardHeader-content  > span{
    font-weight: lighter;
    font-size: 1.2vw;
    width: 10vw;
    height: 14vh;
}

.card-cert {
    border:1px #aaaaaa;
    margin-bottom: 25px;
}

.card-cert > .MuiPaper-root{
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.MuiCardContent-root > p{
    font-size: 16px;
    margin-top: 2%;
}

.boton-cursera {
    margin-top: 60px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.boton-cursera-boton {
    width: 9%;
    margin-left: 10px;
}

.container-redes {
    display: flex;
    margin-bottom: 30px;
    margin-top: 20px;
}

.container-redes_img {
    display: flex;
    width: 100%;
}

.container-redes_mensaje {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 20px;
}

.cert-finish {
    padding: 2px 26px;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .cards-movil {
        display: flex;
    }

    .cards-no-movil {
        display: none !important;
    }

    .display-card-mode {
        display: block;
    }
}
  
@media only screen and (min-width: 600px) {
    .cards-movil {
       display: none; 
    }

    .cards-no-movil {
        display: flex !important;
    }

    .display-card-mode {
        display: flex;
    }
}

.absolute {
    position: absolute;
}

.flex, .pagination-nav {
    display: flex;
}

.inset-0 {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.items-center, .menuExternalLink_tcZa, .row--align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
}


.text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

.font-thin {
    font-weight: lighter;
    font-family: "Open Sans", sans-serif;
    font-size: 3vw;
}

.font-bold {
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
}
.text-6xl {
    font-size: 3.75rem;
    line-height: 1;
}

.text-into{
    padding-left: 5vw;
    width: 33%;
    margin-top: 90px;
}

.parallax-none img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .parallexAspectRatio {
        aspect-ratio: 3/1;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 94%, 0 88%);
        clip-path: polygon(0 0, 100% 0, 100% 94%, 0 88%);
        height: 317px;
        z-index: 1;
    }
    .parallexAspectRatio-text {
        margin-top: 0px;
    }
    
    .parallexAspectRatio div[data-testid="layer-2"] {
        inset: -204px 0px !important;
    }

    .accordion-line-time {
        display: block;
        position: relative;
        top: -40px;
    }

    .accordion-line-time .css-1elwnq4-MuiPaper-root-MuiAccordion-root:last-of-type {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 13px 0px;
        background: #f6f5f5;
    }

    .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper, .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper .Mui-expanded {
        position: relative;
        left: -163px;
    }

    .text-into {
        padding-left: 5vw;
        width: 65%;
    }

    .font-thin {
        font-weight: lighter;
        font-family: "Open Sans", sans-serif;
        font-size: 6vw;
    }

    .parallax-none img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: 30% 0;
    }
}

@media only screen and (max-width: 300px) {
    .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper, .accordion-line-time .css-yw020d-MuiAccordionSummary-expandIconWrapper .Mui-expanded  {
        position: relative;
        left: -66px;
    }
}
  
@media only screen and (min-width: 600px) {
    .parallexAspectRatio {
        aspect-ratio: 3/1;
    }
    .parallexAspectRatio-text {
        margin-top: 90px;
    }

    .accordion-line-time {
        display: none;
    }
}
.content-redes {
    display: flex;

}

.content-redes button {
    width: 60px;
}
.card-main {
    padding: 100px 150px;
}

.form-umbral {
    margin-top: 50px;
}

.form-umbral-grid {
    width: 100%;
}

.form-button {
    text-align: center;
}

.form-button .MuiButton-root {
    border-radius: 40px;
    padding: 20px 72px;
    margin-top: 30px;
    color: white;
}

.form-button-background-morado .MuiButton-root {
    background-color: #4e2378;
}

.form-button-background-morado .MuiButton-root:hover {
    background-color: #361d50;
}

.form-table {
    margin-top: 100px;
}

.form-table h3 {
    margin-bottom: 30px;
}

.table-post-footer {
    text-align: right;
    margin-top: 30px;
}

.table-post-footer span {
    margin-right: 80px;
    font-size: 17px;
}

.dialog {
    text-align: center;
    padding: 81px 40px;
}

.dialog-button .MuiButton-root {
    border-radius: 40px;
    padding: 10px 72px;
    margin-top: 60px
}

.texto-aviso {
    color: red;
}

.caja-mensaje-errores {
    margin-top: 55px;
    color: red;
}

#invalidMessages {
    color: red;
    height: 191px;
    overflow: auto;
    padding: 0px 21px;
    width: 65%;
}

.edit-data {
    display: flex;
}

.edit-data > p, .edit-data > .MuiOutlinedInput-root {
    width: 80%;
}

.edit-data > button {
    width: 20%;
    border: none;
    background: none;
    cursor: pointer;
}


.edit-data > button:hover {
    color: rgb(88, 173, 233);
}

.edit-data > button:disabled {
    color: rgb(179, 179, 179);
    cursor: initial
}

.cancel-data:hover {
    color: red !important;
}

.acept-data:hover {
    color: rgb(9, 190, 30);
}

.acept-data:disabled {
    color: rgb(179, 179, 179);
    cursor: initial
}

@media only screen and (max-width: 600px) {
    .card-main {
        padding: 100px 50px;
    }
}
.card-main {
    padding: 100px 265px;
}

.form-component {
    margin-top: 40px;
}

.text-field {
    width: 60%;
}

.text-field .MuiTextField-root {
    width: 100%;
}

.button {
    margin-top: 36px;
    text-align: center;
}

.button .MuiButton-root {
    background-color:#2E3B55;
}

@media only screen and (max-width: 600px) {
    .card-main {
        padding: 100px 50px;
    }

    .text-field {
        width: 100%;
    }
}
