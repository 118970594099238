@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');

.link {
    color: #4285f4;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

.link-button {
  cursor: pointer;
  text-decoration: underline;
  border: none;
  background: none;
  margin-top: 10px;
}
  
.spanHeader{
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
}

.title {
    flex-grow: 1;
    text-align: right;
    color:gray;
}

.captcha {
  text-align: center;
  margin: 38px 60px;
  color:red;
}

@media only screen and (max-width: 600px) {
  #appbar {
    display: none;
  }

  #appbar-mobil {
    display: block;
  }
  
  .loginButton{
    width: 20%;
  }

  .captcha {
    text-align: center;
    margin: 38px -15px;
    color:red;
  }

  .captcha > div > div > div {
    width: 277px;
  }
}

@media only screen and (min-width: 600px) {
  #appbar {
    display: block;
  }

  #appbar-mobil {
    display: none;
  }

  .loginButton{
    width: 10%;
  }
}